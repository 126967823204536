import React from 'react'

const NoAccount = () => {
  return (
    <div style={{'width': '100%', 'height':'100%', 'display':'flex','justify-content':'center','align-items':'center'}}>
        <h3>Link your socials to get started!</h3>
    </div>
  )
}

export default NoAccount